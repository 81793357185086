import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import About from '../views/AboutView.vue'
import Marvel from '../views/Marvel.vue'
import Disney from '../views/Disney.vue'
import DC from '../views/DC.vue'
import Netflix from '../views/Netflix.vue'
import Login from '../views/Login.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/marvel',
    name: 'Marvel',
    component: Marvel
  }, // Added a comma here
  {
    path: '/disney',
    name: 'Disney',
    component: Disney
  },
  {
    path: '/dc',
    name: 'DC',
    component: DC
  },
  {
    path: '/netflix',
    name: 'Netflix',
    component: Netflix
  },  {
    path: '/netflix',
    name: 'Netflix',
    component: Netflix
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
