<template>
  <v-app style="background-color:black;">
    <v-main>
      <router-view />
    </v-main>
    <!-- Only render NavBar on mobile screens -->
    <NavBar v-if="isMobile" />
  </v-app>
</template>

<script>
import NavBar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      // Initial mobile check
      isMobile: window.innerWidth < 600
    };
  },
  mounted() {
    // Event listener to adjust isMobile based on window resizing
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Clean up event listener when the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 600;
    }
  }
};
</script>

<style>
/* Additional global styles can go here */
</style>
