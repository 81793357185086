<template>
  
    <div>
      <v-app-bar app color="black" >
    <v-toolbar-title class="mx-auto">
      <img src="../assets/Movie.png" class="pa-3" height="130px" alt="Animeflix Logo">
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-text-field
    v-model="searchQuery"
    label="Search movies"
    single-line
  style="width:1px"
    dark
    solo dense class="mx-2"
    hide-details
    @keyup.enter="searchMovies"
  ></v-text-field>
  <v-icon color="white" @click="searchMovies">mdi-magnify</v-icon>
    <!-- <v-btn icon @click="toggleUserMenu">
      <v-icon color="white">mdi-account-circle</v-icon>
    </v-btn> -->
    <v-btn icon @click="redirectToPage">
      <v-icon color="white">mdi-laptop</v-icon>
    </v-btn>
  </v-app-bar>
  <v-row class="scrolling-wrapper">
    <v-col
      v-for="result in searchResults"
      :key="result.imdb_id"
      cols="4" sm="6" md="2">
      <v-card class="my-2" style="width: 110px; background-color: white; border-radius: 20px; overflow: hidden;" @click="showMovieDialog(result.imdb_id)">
        <v-img :src="result.img" height="150px" width="110px"></v-img>
  
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
  
  <v-row class="my-5 " no-gutters align="center" justify="center">
  <v-col class="pa-3" cols="12" sm="12" md="auto" v-for="(movie, index) in  dcComicsMovies" :key="index">
    <div class="movie-card" style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; height: 300px; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
    </div>
  </v-col>
</v-row>

  
  <!-- <h4 class="white--text mx-3 my-4">TV Shows</h4>
  <v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
    <v-col
      v-for="(show, index) in tvShows"
      :key="index"
      cols="auto">
      <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
        <v-img
          @click="playMovie(show.imdb_id)"
          style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
          :src="getImageUrl(show.backdrop_path)">
        </v-img>
   
      </div>
    </v-col>
  </v-row> -->
  
      
      <!-- Movie player dialog -->
      <v-dialog v-model="dialog" max-width="800px">
        <div style="background-color:#f44336;border-radius:20px;">
          <v-card-text>
            <iframe style="border-radius:20px" :src="moviePlayerUrl" width="100%" height="450" frameborder="0" allowfullscreen></iframe>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
     
          </v-card-actions>
        </div>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'App',
    data: () => ({
      movies: [],
      dialog: false,
      comedyMovies: [],
      thrillerMovies: [],
      searchQuery: '',
    searchResults: [],
    dialog: false,
    moviePlayerUrl: '',
      randomMovies: [],
      dcComicsMovies: [],

      tvShows: [], // New array for TV shows
      recentMovies: [],
      marvelMovies: [], // Add this line to store Marvel movies
  
      moviePlayerUrl: '',
      search: '',
        tvShows: [], // Array for TV shows
        continueWatching: [], // This will hold the continue watching data
  
    searchResults: [],
    }),
    created() {
    this.fetchMovies();

this.updateContinueWatching();

  this.fetchDCComicsMovies();
  
  },
  
    methods: {
        fetchDCComicsMovies(page = 1) {
    axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${page}&with_companies=429`) // 429 is the ID for DC Comics
      .then(response => {
        const moviePromises = response.data.results.map(movie => {
          return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
        });

        Promise.all(moviePromises).then(externalIdsResponses => {
          const fetchedMovies = response.data.results.map((movie, index) => {
            const imdbId = externalIdsResponses[index].data.imdb_id;
            return { ...movie, imdb_id: imdbId };
          });

          this.dcComicsMovies = [...this.dcComicsMovies, ...fetchedMovies];  // Adjusted the variable to dcComicsMovies
          console.log('DC Comics Movies:', this.dcComicsMovies);

          // Check if there are more pages to fetch
          if (response.data.page < response.data.total_pages) {
            this.fetchDCComicsMovies(response.data.page + 1);
          }
        });
      })
      .catch(error => {
        console.error('Error fetching DC Comics movies:', error);
      });
  },
    searchMovies() {
    const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
    const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&query=${encodeURIComponent(this.searchQuery)}`;
  
    axios.get(movieUrl)
      .then(response => {
        this.searchResults = response.data.results.map(movie => ({
          title: movie.title,
          img: `https://image.tmdb.org/t/p/w500${movie.poster_path}`,
          year: movie.release_date.split('-')[0],
          imdb_id: movie.id,
          type: 'Movie'
        }));
      }).catch(error => {
        console.error('Error searching movies:', error);
      });
  }
  ,
    showMovieDialog(movieId) {
      // Additional method to fetch movie details or directly open the player dialog
      this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${movieId}`;
      this.dialog = true;
    },
    
   
  searchMovie() {
    if (!this.search) return;
  
    // Prepare API URLs for both movie and TV series searches
    const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;
    const tvUrl = `https://api.themoviedb.org/3/search/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;
  
    // Execute both search queries in parallel using axios.all
    axios.all([
      axios.get(movieUrl),
      axios.get(tvUrl)
    ])
    .then(axios.spread((movieResponse, tvResponse) => {
      // Combine movie and TV search results
      this.searchResults = [...movieResponse.data.results, ...tvResponse.data.results];
  
      // Check if any results are available
      if (this.searchResults.length) {
        // Assuming you want to show the first result, check its type and use the appropriate player
        const firstResult = this.searchResults[0];
        const isMovie = firstResult.hasOwnProperty('title'); // Movies have 'title', TV shows have 'name'
        this.showMoviePlayer(firstResult.id, isMovie);
      } else {
        alert('No movies or TV shows found.');
      }
    }))
    .catch(error => {
      console.error('Error searching for movies or TV shows:', error);
    });
  },

  
      playMovie(imdbId) {
        this.showMoviePlayer(imdbId);
      },
  
      fetchMovies() {
        axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
          .then(response => {
            const moviePromises = response.data.results.map(movie => {
              return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
            });
  
            Promise.all(moviePromises).then(externalIdsResponses => {
              this.movies = response.data.results.map((movie, index) => {
                const imdbId = externalIdsResponses[index].data.imdb_id;
                // Update movie object to include category and rating
                return { ...movie, imdb_id: imdbId, category: 'Category', rating: 'Rating' };
              });
  
              // Log the movies array after it's been populated
              console.log('Movies:', this.movies);
            });
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      },
      redirectToPage() {
        this.$router.push('/about');
      },
      redirectMarvel() {
        this.$router.push('/marvel');
      },
      getImageUrl(path) {
    return `https://image.tmdb.org/t/p/original${path}`;
  }
  ,
      logImdbId(imdbId) {
        console.log('Clicked movie IMDb ID:', imdbId);
      },
      showMoviePlayer(imdbId) {
        this.logImdbId(imdbId);
        this.moviePlayerUrl = ` https://vidsrc.pro/embed/movie/${imdbId}`;
        this.dialog = true;
      },
      updateContinueWatching(movieId, progress) {
        let watching = JSON.parse(localStorage.getItem('continueWatching')) || [];
        let index = watching.findIndex(w => w.imdb_id === movieId);
        
        if (index !== -1) {
          watching[index].progress = progress;
        } else {
          watching.push({ imdb_id: movieId, progress });
        }
  
        localStorage.setItem('continueWatching', JSON.stringify(watching));
      },
  
      loadContinueWatching() {
        this.continueWatching = JSON.parse(localStorage.getItem('continueWatching')) || [];
      },
  
      showMoviePlayer(imdbId) {
        // Call this when you start playing a movie
        this.logImdbId(imdbId);
        this.updateContinueWatching(imdbId, 0); // Assuming starting from 0% progress
        this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${imdbId}`;
        this.dialog = true;
      },
    }
  }
  </script>
  
  <style scoped>
  .carousel-item::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0) 100%);
  }
  .card {
      background: radial-gradient(circle at top, #ff0000, #000000);
      height: 240px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.5);
      transition: transform 0.3s ease, background 0.3s ease;
      position: relative;
      overflow: hidden;
    }
  
    .card img {
      transition: transform 0.3s ease;
      max-height: 100px;
      max-width: 90%;
    }
  
    .card:hover {
      transform: scale(1.05);
    }
  
    .card:hover img {
      transform: scale(1.2);
    }
  
    .card::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0); /* No overlay initially */
      transition: background 0.3s ease;
      border-radius: 20px; /* Match the border radius of the card */
    }
  
    .card:hover::before {
      background: rgba(0, 0, 0, 0.5); /* Dark overlay on hover */
    }


  
    @media (max-width: 600px) {
  .my-5 {
    overflow-x: auto; /* This mimics your 'scrolling-wrapper' functionality */
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch; /* Improves scrolling on iOS devices */
  }
  .movie-card {
    display: inline-block; /* Ensures cards are lined up horizontally */
    float: none;
  }
}

/* General styles for .movie-card if needed outside media query */
.movie-card {
  width: 200px;
  margin: 0 10px;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
}
  
  .white--text {
    color: white !important;
  }
  
  .movie-info {
    font-size: 1em;
    margin-top: 0.5em;
  }
  /* Add this CSS to your stylesheet or style block */
  .v-toolbar__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; /* Adjust the height as needed */
  }
  
  .scrolling-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .scrolling-wrapper::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  @media (max-width: 600px) {  /* Adjust the max-width as needed to target your specific mobile breakpoint */
    .responsive-row {
      display: none;
    }
  }
  
  .scrolling-wrapper {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  }
  @media (max-width: 600px) {
    .movie-description {
      font-size: 10px; /* Smaller font size for mobile screens */
    }
  }
  @media (max-width: 600px) {
  .v-col {
    display: flex;
    justify-content: center; /* Center-align the cards within the column */
  }
}
  .v-col {
    display: inline-block;
    float: none;
  }
  </style>
  