<template>
  
  <div>
    <v-app-bar app color="black" >
  <v-toolbar-title class="mx-auto">
    <img src="../assets/Movie.png" class="pa-3 mt-3" height="130px" alt="Animeflix Logo">
  </v-toolbar-title>
  <h4 class="nav-item mx-3" style="color:white">Home</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectToPage">Tv Shows</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectNetflix">New & Popular</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectNetflix">My List</h4>

  <v-spacer></v-spacer>
  <v-text-field
  v-model="searchQuery"
  label="Search movies"
  single-line
style="width:150px"
v-if="field"
  dark
  solo dense class="mx-2"
  hide-details
  
  @keyup.enter="searchMovies"
></v-text-field>
<v-icon color="white"  @click="field = !field">mdi-magnify</v-icon>
  <!-- <v-btn icon @click="toggleUserMenu">
    <v-icon color="white">mdi-account-circle</v-icon>
  </v-btn> -->

  <v-btn icon @click="redirectToPage">
    <v-icon color="white">mdi-bell</v-icon>
  </v-btn>
  <v-btn class="nav-item" icon @click="redirectToPage">
    <v-icon color="white">mdi-account</v-icon>
  </v-btn>
</v-app-bar>
<v-row class="scrolling-wrapper">
  <v-col
    v-for="result in searchResults"
    :key="result.imdb_id"
    class="mx-3"
    cols="4" sm="6" md="1">
    <v-card class="my-2" style="width: 110px; background-color: white; border-radius: 20px; overflow: hidden;" @click="showMovieDialog(result.imdb_id)">
      <v-img :src="result.img" height="150px" width="110px"></v-img>

      </v-card-title>
    </v-card>
  </v-col>
</v-row>

<v-carousel  hide-delimiters height="650px" :interval="12000" cycle>
  <v-carousel-item
    v-for="(movie, index) in movies"
    :key="movie.id"
    @mouseenter="hoverMovie(movie.trailerUrl)"
    @mouseleave="unhoverMovie"
    @click="showMoviePlayer(movie.imdb_id)"
    
  >
    <v-img 
      :src="getImageUrl(movie.backdrop_path)" 
      height="100%"
      width="100%"
      class="d-flex align-end"
      style="position: relative; overflow: hidden; width: 100%; height: 650px;"
    >
      <!-- IFrame for video playback -->
      <iframe 
  ref="videoIframe"
  @click="playVideoWithSound"
  :src="hoveredUrl"
  frameborder="0"
  allow="autoplay; fullscreen; encrypted-media"
  style="position: absolute; top: 50%; left: 50%; width: 177.77777778vh; height: 56.25vw; min-width: 100%; min-height: 100%; transform: translate(-50%, -50%);"
></iframe>



      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.9) 90%, rgba(0,0,0,1) 100%);"></div>

      <!-- Overlay container for text and buttons -->
      <v-container fluid class="fill-height overlay-container" style="position: absolute; top: 0; left: 0;">
        <v-row no-gutters align="center" justify="start">
          <v-col cols="12">
            <div class="overlay-text pa-5">


              <span style="color:#f44336" class="movie-title">{{ movie.title }}</span>
              <p class="movie-description white--text" style="opacity:70%">
                {{ movie.releaseDate.split('-')[0] }} | {{ movie.genres }}
              </p>
              <p class="movie-description white--text">{{ movie.overview }}</p>
              <!-- <p class="movie-info white--text">
                <v-icon color="yellow" class="mx-2 my-2">mdi-star</v-icon>{{ movie.vote_average }}
              </p> -->
              <v-btn width="120px" height="45px" color="red" dark @click="playMovie(movie)">Play</v-btn>
              <v-btn width="120px" height="45px" outlined class="white--text mx-4" @click="showDetails(movie)">More</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-carousel-item>
</v-carousel>


    <v-row class="pa-3 my-3 responsive-row" style="margin-top:-50px;">
  <v-col @click="redirectDisney" >
    <div class="card disney">
      <img src="https://www.edigitalagency.com.au/wp-content/uploads/Disney-logo-white-png.png" alt="Disney logo">
    </div>
  </v-col>
  <v-col>
    <div @click="redirectNetflix" class="card netflix">
      <img src="https://www.freepnglogos.com/uploads/black-netflix-logo-png-4.png" alt="Netflix logo">
    </div>
  </v-col>
  <v-col  @click="redirectMarvel">
    <div class="card marvel">
      <img src="https://www.freepnglogos.com/uploads/marvel-logo-png/marvel-logo-hd-5.png"  alt="Marvel logo">
    </div>
  </v-col>
  <v-col  @click="redirectDc">
    <div class="card dc">
      <img style="height:110px" src="https://firebasestorage.googleapis.com/v0/b/movie-site-9a217.appspot.com/o/pngaaa.com-112647.png?alt=media&token=517d21f1-2f82-498e-817a-65b828ac61d1" alt="DC Comics logo">
    </div>
  </v-col>
</v-row>

<!-- <h4 class="white--text mx-3 my-4">Continue Watching</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(item, index) in continueWatching" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="showMoviePlayer(item.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(item.poster_path)"></v-img>
      <div style="padding: 10px;">
        <v-progress-linear :value="item.progress"></v-progress-linear>
      </div>
    </div>
  </v-col>
</v-row> -->

    <h4 class="white--text mx-3 my-4"> Discover Movies</h4>
    <v-row
  class="scrolling-wrapper my-4"
  no-gutters
  align="center"
  justify="start">
  <v-col
    v-for="(movie, index) in randomMovies"
    :key="index"
    cols="auto"> <!-- Use "auto" to let the column width be as wide as the content -->
    <div class="movies1" style=" margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;"> <!-- Set a specific width for the card and margin for spacing -->
      <v-img
        @click="playMovie(movie.imdb_id)"
        class="movies"
        style="border-radius: 20px 20px 0 0; width: 100%; object-fit: cover;"
        :src="getImageUrl(movie.poster_path)">
      </v-img>
      <!-- The rest of the card content here -->
    </div>
  </v-col>
</v-row>

<h4 class="white--text mx-3 my-4">Recent Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in recentMovies" :key="index" cols="auto">
    <div class="movies1" style=" margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img class="movies" @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>
<h4 class="white--text mx-3 my-4">Comedy Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in comedyMovies" :key="index" cols="auto">
    <div class="movies1" style=" margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img class="movies" @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>
<h4 class="white--text mx-3 my-4">Thriller Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in thrillerMovies" :key="index" cols="auto">
    <div class="movies1" style=" margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img class="movies" @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%;  object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>

<!-- <h4 class="white--text mx-3 my-4">TV Shows</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col
    v-for="(show, index) in tvShows"
    :key="index"
    cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img
        @click="playMovie(show.imdb_id)"
        style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
        :src="getImageUrl(show.backdrop_path)">
      </v-img>
 
    </div>
  </v-col>
</v-row> -->

    
    <!-- Movie player dialog -->
    <v-dialog v-model="dialog" max-width="800px">
      <div style="background-color:black;border-radius:20px;">
        <v-card-text>
          <iframe style="border-radius:20px" :src="moviePlayerUrl" width="100%" height="450" frameborder="0" allowfullscreen></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
   
        </v-card-actions>
      </div>
      
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data: () => ({
    movies: [],
    dialog: false,
    field: false,
    comedyMovies: [],
    thrillerMovies: [],
    searchQuery: '',
  searchResults: [],
  dialog: false,
  hoveredUrl: null,
  moviePlayerUrl: '',
    randomMovies: [],
    tvShows: [], // New array for TV shows
    recentMovies: [],
    marvelMovies: [], // Add this line to store Marvel movies

    moviePlayerUrl: '',
    search: '',
      tvShows: [], // Array for TV shows
      continueWatching: [], // This will hold the continue watching data

  searchResults: [],
  }),
  created() {
  this.fetchMovies();
  this.fetchComedyMovies();
  this.fetchRandomMovies();
  this.fetchTVShows();
  this.fetchThrillerMovies();
  this.loadContinueWatching();
this.fetchMarvelMovies();
  this.fetchRecentMovies(); // Add this line
},

  methods: {
    hoverMovie(trailerUrl) {
    this.hoveredUrl = trailerUrl;
  },
  unhoverMovie() {
    this.hoveredUrl = null;
  },
    fetchMarvelMovies() {
  axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_companies=420`) // 420 is the ID for Marvel Studios
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.marvelMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          return { ...movie, imdb_id: imdbId };
        });
        console.log('Marvel Movies:', this.marvelMovies);
      });
    })
    .catch(error => {
      console.error('Error fetching Marvel movies:', error);
    });
},
playVideoWithSound() {
    let iframe = this.$refs.videoIframe;
    if (iframe) {
      iframe.contentWindow.postMessage('{"event":"command","func":"unmute","args":""}', '*');
      // Check if the browser blocked the autoplay with sound
      const promise = iframe.contentWindow.play();
      if (promise !== undefined) {
        promise.then(() => {
          // Autoplay started
        }).catch(error => {
          // Autoplay was prevented
          // Show a play button to the user to start playback with sound
        });
      }
    }
  },
    fetchTVShows() {
  axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
    .then(response => {
      const tvShowPromises = response.data.results.map(tvShow => {
        return axios.get(`https://api.themoviedb.org/3/tv/${tvShow.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(tvShowPromises).then(externalIdsResponses => {
        this.tvShows = response.data.results.map((tvShow, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id || 'N/A';
          return { ...tvShow, imdb_id: imdbId, category: 'TV Show' };
        });
        console.log('TV Shows:', this.tvShows);
      });
    })
    .catch(error => {
      console.error('Error fetching TV shows:', error);
    });
},

  fetchThrillerMovies() {
    axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100&with_genres=53`)
      .then(response => {
        const moviePromises = response.data.results.map(movie => {
          return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
        });

        Promise.all(moviePromises).then(externalIdsResponses => {
          this.thrillerMovies = response.data.results.map((movie, index) => {
            const imdbId = externalIdsResponses[index].data.imdb_id;
            // Update movie object to include category and rating
            return { ...movie, imdb_id: imdbId, category: 'Thriller', rating: 'Rating' };
          });

          // Log the thrillerMovies array after it's been populated
          console.log('Thriller Movies:', this.thrillerMovies);
        });
      })
      .catch(error => {
        console.error('Error fetching thriller movies:', error);
      });
  },
  searchMovies() {
  const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
  const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&query=${encodeURIComponent(this.searchQuery)}`;

  axios.get(movieUrl)
    .then(response => {
      this.searchResults = response.data.results.map(movie => ({
        title: movie.title,
        img: `https://image.tmdb.org/t/p/w500${movie.poster_path}`,
        year: movie.release_date.split('-')[0],
        imdb_id: movie.id,
        type: 'Movie'
      }));
    }).catch(error => {
      console.error('Error searching movies:', error);
    });
}
,
  showMovieDialog(movieId) {
    // Additional method to fetch movie details or directly open the player dialog
    this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${movieId}`;
    this.dialog = true;
  },
    fetchComedyMovies() {
    axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100&with_genres=35`)
      .then(response => {
        const moviePromises = response.data.results.map(movie => {
          return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
        });

        Promise.all(moviePromises).then(externalIdsResponses => {
          this.comedyMovies = response.data.results.map((movie, index) => {
            const imdbId = externalIdsResponses[index].data.imdb_id;
            // Update movie object to include category and rating
            return { ...movie, imdb_id: imdbId, category: 'Comedy', rating: 'Rating' };
          });

          // Log the comedyMovies array after it's been populated
          console.log('Comedy Movies:', this.comedyMovies);
        });
      })
      .catch(error => {
        console.error('Error fetching comedy movies:', error);
      });
  },
  
    fetchRandomMovies() {
  axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100`)
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.randomMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          // Update movie object to include category and rating
          return { ...movie, imdb_id: imdbId, category: 'Category', rating: 'Rating' };
        });

        // Log the randomMovies array after it's been populated
        console.log('Random Movies:', this.randomMovies);
      });
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
},
searchMovie() {
  if (!this.search) return;

  // Prepare API URLs for both movie and TV series searches
  const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;
  const tvUrl = `https://api.themoviedb.org/3/search/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;

  // Execute both search queries in parallel using axios.all
  axios.all([
    axios.get(movieUrl),
    axios.get(tvUrl)
  ])
  .then(axios.spread((movieResponse, tvResponse) => {
    // Combine movie and TV search results
    this.searchResults = [...movieResponse.data.results, ...tvResponse.data.results];

    // Check if any results are available
    if (this.searchResults.length) {
      // Assuming you want to show the first result, check its type and use the appropriate player
      const firstResult = this.searchResults[0];
      const isMovie = firstResult.hasOwnProperty('title'); // Movies have 'title', TV shows have 'name'
      this.showMoviePlayer(firstResult.id, isMovie);
    } else {
      alert('No movies or TV shows found.');
    }
  }))
  .catch(error => {
    console.error('Error searching for movies or TV shows:', error);
  });
},
fetchRecentMovies() {
  axios.get(`https://api.themoviedb.org/3/movie/now_playing?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.recentMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          return { ...movie, imdb_id: imdbId };
        });
        console.log('Recent Movies:', this.recentMovies);
      });
    })
    .catch(error => {
      console.error('Error fetching recent movies:', error);
    });
},

    playMovie(imdbId) {
      this.showMoviePlayer(imdbId);
    },
    fetchMovies() {
  console.log("Fetching movies...");
  // Fetching popular movies, including their details like genres
  axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1&append_to_response=videos`)
    .then(response => {
      // Process each movie to fetch additional details like external IDs and videos
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&append_to_response=videos`)
          .then(movieDetailResponse => {
            // Access additional details like external IDs and trailers
            const movieDetails = movieDetailResponse.data;
            const videos = movieDetails.videos.results;
            const trailer = videos.find(video => video.type === 'Trailer' && video.site === 'YouTube');

            return {
              ...movieDetails,
              imdb_id: movieDetails.external_ids ? movieDetails.external_ids.imdb_id : undefined,
              genres: movieDetails.genres.map(genre => genre.name).join(', '),
              releaseDate: movieDetails.release_date,
              trailerUrl: trailer ? `https://www.youtube.com/embed/${trailer.key}?autoplay=1&mute=1&controls=0&fs=0&loop=1&playlist=${trailer.key}` : null // Adding loop for continuous play
            };
          });
      });

      // Wait for all movie details to be processed
      Promise.all(moviePromises).then(movies => {
        this.movies = movies;
        console.log("All movie data processed:", movies);
      });
    })
    .catch(error => {
      console.error('There was an error fetching movies:', error);
    });
}
,

    redirectToPage() {
      this.$router.push('/about');
    },
    redirectMarvel() {
      this.$router.push('/marvel');
    },
    redirectDisney() {
      this.$router.push('/disney');
    },
    redirectDc() {
      this.$router.push('/dc');
    },
    redirectNetflix() {
      this.$router.push('/netflix');
    },
    getImageUrl(path) {
  return `https://image.tmdb.org/t/p/original${path}`;
}
,
    logImdbId(imdbId) {
      console.log('Clicked movie IMDb ID:', imdbId);
    },
    showMoviePlayer(imdbId) {
      this.logImdbId(imdbId);
      this.moviePlayerUrl = ` https://vidsrc.pro/embed/movie/${imdbId}`;
      this.dialog = true;
    },
    updateContinueWatching(movieId, progress) {
      let watching = JSON.parse(localStorage.getItem('continueWatching')) || [];
      let index = watching.findIndex(w => w.imdb_id === movieId);
      
      if (index !== -1) {
        watching[index].progress = progress;
      } else {
        watching.push({ imdb_id: movieId, progress });
      }

      localStorage.setItem('continueWatching', JSON.stringify(watching));
    },

    loadContinueWatching() {
      this.continueWatching = JSON.parse(localStorage.getItem('continueWatching')) || [];
    },

    showMoviePlayer(imdbId) {
      // Call this when you start playing a movie
      this.logImdbId(imdbId);
      this.updateContinueWatching(imdbId, 0); // Assuming starting from 0% progress
      this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${imdbId}`;
      this.dialog = true;
    },
  }
}
</script>

<style scoped>
.carousel-item::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0) 100%);
}
.card {
    background: radial-gradient(circle at top, #ff0000, #000000);
    height: 240px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.5);
    transition: transform 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
  }

  .card img {
    transition: transform 0.3s ease;
    max-height: 100px;
    max-width: 90%;
  }

  .card:hover {
    transform: scale(1.05);
  }

  .card:hover img {
    transform: scale(1.2);
  }
  @media (max-width: 600px) {
  .nav-item {
    display: none;
  }
}
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0); /* No overlay initially */
    transition: background 0.3s ease;
    border-radius: 20px; /* Match the border radius of the card */
  }

  .card:hover::before {
    background: rgba(0, 0, 0, 0.5); /* Dark overlay on hover */
  }
  .disney:hover {
    background: url('https://wallpapers.com/images/high/disney-computer-wallpaper-r7tmydo1x032cwym.webp') center/cover no-repeat;
  }

  .netflix:hover {
    background: url('https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTEyL3Jhd3BpeGVsX29mZmljZV80Nl9ibGFja193YWxscGFwZXJfbmVvbl9saWdodF9taW5pbWFsX3NpbXBsZV9iZmIzZTBiNi0xNjNjLTRhZDYtOWE1Ny01MjNjNjU4ODY4MmRfMS5qcGc.jpg') center/cover no-repeat;
  }

  .marvel:hover {
    background: url('https://wallpapers.com/images/featured/marvel-aesthetic-laptop-0goyjc3omk6gyd17.webp') center/cover no-repeat;
  }

  .dc:hover {
    background: url('https://wallpapers.com/images/high/dc-superheroes-in-arcade-2pgn6nu6ahyjxick.webp') center/cover no-repeat;
  }
.movie-title {
  font-size: 50px;
  font-weight: bold;
}
@media (max-width: 600px) {
  .movie-title {
    font-size: 30px; /* Smaller font size for mobile devices */
  }
}
.movies1 {
  width: 200px; /* Adjust according to your default design for desktop screens */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .movies1 {
    width: 140px; /* Adjust this width as needed for mobile devices */
  }
}
.movie-description {
  font-size: 1.1em;
  margin-top: 0.5em;
  max-width: 45%;
}

.overlay-text {
  position: absolute;
  bottom: 20px;
  left: 40px;
  padding: 0; /* Ensuring no padding */
}

.v-carousel-item,
.v-carousel {
  background-size: cover !important;
  background-position: center center !important;
}


.white--text {
  color: white !important;
}

.movie-info {
  font-size: 1em;
  margin-top: 0.5em;
}
/* Add this CSS to your stylesheet or style block */
.v-toolbar__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
}
.movies {
  height: 300px; /* Adjust based on the desktop design */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .movies {
    height: 200px; /* Adjust as needed for mobile devices */
  }
}
.scrolling-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
@media (max-width: 600px) {  /* Adjust the max-width as needed to target your specific mobile breakpoint */
  .responsive-row {
    display: none;
  }
}

.scrolling-wrapper {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}
@media (max-width: 600px) {
  .movie-description {
    font-size: 10px; /* Smaller font size for mobile screens */
  }
}
.v-carousel-item {
  position: relative;
  overflow: hidden;
}

.v-carousel-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.v-col {
  display: inline-block;
  float: none;
}
</style>
