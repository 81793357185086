<template>
  <div cols="12">
    <v-app-bar app color="black">
      <v-toolbar-title class="mx-auto">
        <img
          src="../assets/Movie.png"
          class="pa-3 mt-4"
          height="130px"
          alt="Animeflix Logo"
        />
      </v-toolbar-title>
      <h4 class="nav-item mx-3" style="color:white">Home</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectToPage">Tv Shows</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectNetflix">New & Popular</h4>
  <h4 class="nav-item mx-3" style="color:white" @click="redirectNetflix">My List</h4>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchQuery"
        dense
        label="Search TV shows"
        single-line
        clearable
        class="my-2"
        hide-details
        v-if="field"
        solo
        dark
        @keyup.enter="searchTVShows"
      ></v-text-field>
      <v-icon  @click="field = !field" color="white">mdi-magnify</v-icon>
      <v-btn icon @click="redirectToPage">
    <v-icon color="white">mdi-bell</v-icon>
  </v-btn>
  <v-btn icon @click="redirectToPage">
    <v-icon color="white">mdi-account</v-icon>
  </v-btn>

    </v-app-bar>
    
    <v-row
    v-if="searchResults.length > 0"
      class="scrolling-wrapper my-4 mx-10"
      no-gutters
      align="center"
      justify="start"
    >
      <v-col
        v-for="(show, index) in searchResults.length > 0
          ? searchResults
          : tvShows"
        :key="index"
        cols="4" sm="6" md="1" class="mx-3"
      >
        <div
          style="
            width: 110px;
            margin: 0 10px;
            background-color: white;
            border-radius: 20px;
            overflow: hidden;
          "
        >
          <!-- Updated @click event to pass the show object to the showDialog method -->
         <v-img
         @click="playMovie(show)"

  :src="getImageUrl(show.backdrop_path)"
  style="border-radius: 20px 20px 0 0;  object-fit: cover;"
  height="150px" width="110px"
></v-img>

        </div>
      </v-col>
    </v-row>

    <v-carousel cycle height="650" hide-delimiters>
<v-carousel-item
  v-for="(show, index) in popularShows"
  :key="index"
  :src="getImageUrl(show.backdrop_path)"
  @click="playMovie(show)"
>

  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.9) 90%, rgba(0,0,0,1) 100%);"></div>

    <v-row class="fill-height" align="center" justify="center">
      <v-col class="text-center">
        <div class="overlay-text">
  <span style="color:#f44336"  class="movie-title">{{ show.name }}</span>
  <p class="movie-description text-white">{{ show.overview }}</p>
  <v-btn width="120px" height="45px" color="red" dark @click="playMovie(show)">Play</v-btn>
</div>

      </v-col>
    </v-row>
  </v-carousel-item>
</v-carousel>
<div v-if="continueWatchingShows.length > 0">
  <h4 class="white--text mx-12 my-6">Continue Watching</h4>
  <v-row class="scrolling-wrapper my-4 mx-10" no-gutters align="center" justify="start">
    <v-col v-for="(show, index) in continueWatchingShows" :key="index" cols="auto">
      <div
        style="
          width: 200px;
          margin: 0 10px;
          background-color: white;
          border-radius: 20px;
          overflow: hidden;
        "
      >
        <v-img
          @click="showDialog(show)"
          :src="getImageUrl(show.backdrop_path)"
          style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
        ></v-img>
      </div>
    </v-col>
  </v-row>
</div>
<h4 class="white--text mx-12 my-6">Comedy TV Shows</h4>

<v-row
  class="scrolling-wrapper my-4 mx-10"
  no-gutters
  align="center"
  justify="start"
>
  <v-col
    v-for="(show, index) in comedyTVShows"
    :key="index"
    cols="auto"
  >
    <div
      style="
        width: 200px;
        margin: 0 10px;
        background-color: white;
        border-radius: 20px;
        overflow: hidden;
      "
    >
      <v-img
        @click="showDialog(show)"
        :src="getImageUrl(show.backdrop_path)"
        style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
      ></v-img>
    </div>
  </v-col>
</v-row>
<!-- Check if the continueWatchingShows array is not empty -->


    <v-dialog v-model="dialog" max-width="800px">
      <v-card
        style="background-color:black; border-radius: 20px; padding: 20px"
      >
        <v-card-text>
          <v-row class="my-2">
            <iframe
              :src="moviePlayerUrl"
              width="100%"
              height="380"
              frameborder="0"
              allowfullscreen
              style="border-radius:20px;"
            ></iframe>
          </v-row>
          <v-row class="my-3">
            <v-col cols="12" sm="6">
              <v-select
                dense
                dark
                v-model="selectedSeason"
                :items="getSeasonOptions()"
                label="Season"
                @change="onSeasonOrEpisodeChange"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                dense
                dark
                v-model="selectedEpisode"
                :items="getEpisodeOptions()"
                label="Episode"
                @change="onSeasonOrEpisodeChange"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data: () => ({
    movies: [],
    dialog: false,
    searchQuery: "",
    searchResults: [],
    popularShows: [], // This will hold the array of popular TV shows
    comedyTVShows: [],
    continueWatchingShows: [], // New property to hold shows for "Continue Watching"

    moviePlayerUrl: "",
    selectedShow: null,
    selectedSeason: null,
    selectedEpisode: null,
    tvShows: [],
    field: false,

  }),
  created() {
    this.fetchTVShows();
    this.fetchPopularTVShows(); // Call this method when the component is created
    this.fetchComedyTVShows(); // Fetch comedy TV shows when the component is created
    this.loadContinueWatching();

  },
  methods: {
    showDialog(show) {
  this.selectedShow = show;
  // Set the season and episode from stored data, or default to the first available
  this.selectedSeason = show.lastWatchedSeason || (show.seasons && show.seasons[0].season_number);
  this.selectedEpisode = show.lastWatchedEpisode || 1;
  this.updatePlayerUrl();
  this.dialog = true;
},


addShowToContinueWatching(show) {
  let shows = JSON.parse(localStorage.getItem('continueWatching')) || [];
  // Remove existing entry if it exists
  shows = shows.filter((s) => s.id !== show.id);
  // Create a new entry for the show with season and episode
  const newEntry = {
    ...show,
    lastWatchedSeason: this.selectedSeason,
    lastWatchedEpisode: this.selectedEpisode
  };
  // Add the updated show to the beginning of the array
  shows.unshift(newEntry);
  // Limit to the most recent 10 shows
  shows = shows.slice(0, 10);
  // Save back to localStorage
  localStorage.setItem('continueWatching', JSON.stringify(shows));
  // Update the component data
  this.continueWatchingShows = shows;
},

    // Load shows from localStorage into data property
    loadContinueWatching() {
  const shows = JSON.parse(localStorage.getItem('continueWatching')) || [];
  this.continueWatchingShows = shows;
},

fetchComedyTVShows() {
    axios.get(`https://api.themoviedb.org/3/discover/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&with_genres=35`)
      .then(response => {
        this.comedyTVShows = response.data.results; // Storing the fetched comedy TV shows
      })
      .catch(error => console.error("Error fetching comedy TV shows:", error));
},

fetchPopularTVShows() {
    axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
      .then(response => {
        this.popularShows = response.data.results;
      })
      .catch(error => console.error("Error fetching popular TV shows:", error));
  },

  getImageUrl(path) {
    if (!path) {
      return "path_to_a_default_image"; // Ensure you have a fallback
    }
    return `https://image.tmdb.org/t/p/original${path}`;
  },
    fetchTVShows() {
      // Generate a random page number
      const randomPage = Math.floor(Math.random() * 100) + 1; // Assuming there are at least 100 pages of results

      axios
        .get(
          `https://api.themoviedb.org/3/discover/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&page=${randomPage}`
        )
        .then((response) => {
          this.tvShows = response.data.results;
          this.fetchSeasonsDetails(this.tvShows); // Fetch season details for each show
        })
        .catch((error) => {
          console.error("Error fetching TV shows:", error);
        });
    },
    fetchSeasonsDetails(shows) {
      const requests = shows.map((show) =>
        axios.get(
          `https://api.themoviedb.org/3/tv/${show.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
        )
      );
      axios
        .all(requests)
        .then(
          axios.spread((...responses) => {
            for (let i = 0; i < shows.length; i++) {
              shows[i].seasons = responses[i].data.seasons;
              shows[i].season_number = responses[i].data.seasons.length;
              shows[i].number_of_episodes = responses[i].data.seasons.map(
                (season) => season.episode_count
              );
            }
            if (this.searchResults.length > 0) {
              this.searchResults = shows; // Update search results with season details
            } else {
              this.tvShows = shows; // Update initial tv shows data
            }
          })
        )
        .catch((errors) => {
          console.error("Error fetching seasons details:", errors);
        });
    },
    getSeasonOptions() {
      if (this.selectedShow && this.selectedShow.seasons) {
        return this.selectedShow.seasons.map((season) => season.season_number);
      }
      return [];
    },
    redirectToPage() {
      this.$router.push('/about');
    },
    redirectMarvel() {
      this.$router.push('/marvel');
    },
    redirectDisney() {
      this.$router.push('/disney');
    },
    redirectDc() {
      this.$router.push('/dc');
    },
    redirectNetflix() {
      this.$router.push('/netflix');
    },
    getImageUrl(path) {
  if (!path) {
    return "path_to_a_default_image";  // Ensure you have a fallback
  }
  return `https://image.tmdb.org/t/p/original${path}`;
}
,
    getEpisodeOptions() {
      if (
        this.selectedShow &&
        this.selectedSeason &&
        Array.isArray(this.selectedShow.seasons)
      ) {
        const season = this.selectedShow.seasons.find(
          (s) => s.season_number === this.selectedSeason
        );
        if (season) {
          return Array.from({ length: season.episode_count }, (_, i) => i + 1);
        }
      }
      return [];
    },
    playMovie(show) {
      this.selectedShow = show;
      // Set defaults for season and episode
      this.selectedSeason = 1;
      this.selectedEpisode = 1;

      // Store this show as recently watched
      this.addShowToContinueWatching(show);

      // Check if season details are already fetched, if not, fetch them
      if (!show.seasons || show.seasons.length === 0) {
        axios.get(`https://api.themoviedb.org/3/tv/${show.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`)
          .then(response => {
            show.seasons = response.data.seasons;
            // After fetching, update the URL to play the first episode of the first season
            this.updatePlayerUrl();
            this.dialog = true;
          })
          .catch(error => console.error("Error fetching season details for the show:", error));
      } else {
        // If season details are already present
        this.updatePlayerUrl();
        this.dialog = true;
      }
    },
    updatePlayerUrl() {
        if (this.selectedShow && this.selectedSeason && this.selectedEpisode) {
            // This URL structure may need to be adjusted depending on your video source
            this.moviePlayerUrl = `https://vidsrc.pro/embed/tv/${this.selectedShow.id}/${this.selectedSeason}/${this.selectedEpisode}`;
        }
    },
    onSeasonOrEpisodeChange() {
  // Update the URL for the player
  this.updatePlayerUrl();
  // Update the stored continue watching list with the new season and episode
  if (this.selectedShow) {
    this.addShowToContinueWatching({
      ...this.selectedShow,
      lastWatchedSeason: this.selectedSeason,
      lastWatchedEpisode: this.selectedEpisode
    });
  }
},

    searchTVShows() {
      if (this.searchQuery.trim() === "") {
        this.searchResults = [];
        return;
      }
      const queryUrl = `https://api.themoviedb.org/3/search/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&query=${encodeURIComponent(
        this.searchQuery
      )}`;
      axios
        .get(queryUrl)
        .then((response) => {
          this.searchResults = response.data.results;
          this.fetchSeasonsDetails(this.searchResults); // Fetch season details for search results
        })
        .catch((error) => {
          console.error("Error searching TV shows:", error);
        });
    },
    redirectToPage() {
      this.$router.push("/");
    },
  },
  watch: {
    selectedSeason(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onSeasonOrEpisodeChange();
      }
    },
    selectedEpisode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onSeasonOrEpisodeChange();
      }
    },
  },
};
</script>
<style scoped>
.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.2) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}


.overlay-text {
  position: absolute;
  bottom: 20px;
  left: 40px; /* Adjust this as necessary to align with the left margin */
  text-align: left; /* Align text to the left */
  padding: 0; /* Ensuring no padding on the sides */
  max-width: 60%; /* Adjust as necessary */
}
@media (max-width: 600px) {
  .nav-item {
    display: none;
  }
}

.v-carousel-item,
.v-carousel {
  padding: 0 !important; /* Removing padding */
  background-size: cover !important;
  background-position: center center !important;
}
.text-white {
  color: white !important;
}

.display-1 {
  font-size: 30px; /* Adjust size as needed */
}

.subtitle-1 {
  font-size: 16px; /* Adjust size as needed */
}

.white--text {
  color: white !important;
}

.movie-info {
  font-size: 1em;
  margin-top: 0.5em;
}
/* Add this CSS to your stylesheet or style block */
.v-toolbar__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
}

.scrolling-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.scrolling-wrapper {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}
@media (max-width: 600px) {
  .movie-description {
    font-size: 10px; /* Smaller font size for mobile screens */
  }
}
.v-col {
  display: inline-block;
  float: none;
}
.movie-title {
  font-size: 50px;
  font-weight: bold;
}
@media (max-width: 600px) {
  .movie-title {
    font-size: 30px; /* Smaller font size for mobile devices */
  }
}


/* General style for movie descriptions */
.movie-description {
  font-size: 1.2em; /* Default font size */
  max-width: 60%; /* Adjust as needed */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Add shadow for better visibility */
  color: white; /* Text color */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .movie-description {
    font-size: 1em; /* Smaller font size for tablet screens */
  }
}

@media (max-width: 768px) {
  .movie-description {
    font-size: 0.9em; /* Even smaller font size for small tablet screens */
  }
}

@media (max-width: 600px) {
  .movie-description {
    font-size: 0.75em; /* Significantly smaller font size for mobile screens */
  }
}

.play-button {
  /* Adjust styles to match the image */
  font-size: 1.5em;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 20px; /* Space from the description */
  text-transform: uppercase; /* if you want the text to be all caps */
  /* You might need to add custom styles or classes to position it as per the image */
}
/* Style for cards in the grid */
.card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

/* Hover effect for card scaling */
.card:hover {
  transform: scale(1.1);
  z-index: 10; /* Ensure the card pops out over others */
}

/* Style for the overlay with title and description */
.card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay for text readability */
  color: white;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

/* Style for the play button */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Reveal card-info and play button on hover */
.card:hover .card-info,
.card:hover .play-button {
  transform: translateY(0);
  opacity: 1;
}


</style>
